import { gql } from '@apollo/client';

export const GetActiveSASubscriptionsQuery = gql`
    query getActiveSASubscriptions {
        activeSASubscriptions {
            id
            hasSynchroartsArtist
            hasSynchroartsProfessional
            hasSynchroartsTrialSubscription
            hasSynchroartsMonthlySubscription
            trialEndDate
        }
    }
`;

export const ActivateTrialSubscriptionMutation = gql`
    mutation activateTrialSubscription {
        activateTrialSubscription {
            subscription {
                id
                hasSynchroartsArtist
                hasSynchroartsProfessional
                hasSynchroartsTrialSubscription
                trialEndDate
            }
        }
    }
`;
