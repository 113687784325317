import React from 'react';
import { Flex, Modal } from '@landr/maestro';
import { useMutation } from '@apollo/client';
import { ActivateTrialSubscriptionMutation } from 'apollo/graphql/subscription/subscription.gql';
import {
    ActivateTrialSubscriptionMutationVariables,
    ActivateTrialSubscriptionMutation as ActivateTrialSubscriptionMutationType,
} from 'types/generated/graphql';
import { PluginCatalogQuery } from 'apollo/graphql/plugins/plugins.gql';
import { useActivateTrialSubscriptionModal } from './useActivateTrialSubscriptionModal';
import { ActivateTrialSubscriptionModalContent } from './components/ActivateTrialSubscriptionModalContent';

export const ActivateTrialSubscriptionModal: React.FC = () => {
    const { isModalVisible, closeModal } = useActivateTrialSubscriptionModal();

    const [activateTrialSubscription, { loading, data, error, reset }] =
        useMutation<
            ActivateTrialSubscriptionMutationType,
            ActivateTrialSubscriptionMutationVariables
        >(ActivateTrialSubscriptionMutation, {
            refetchQueries: [PluginCatalogQuery],
        });

    const handleSubmitToBff = async (): Promise<void> => {
        await activateTrialSubscription();
    };

    const handleClose = () => {
        closeModal();
        setTimeout(reset, 1000);
    };

    return (
        <Modal
            isOpen={isModalVisible}
            onClose={handleClose}
            isDismissible={!loading}
            size="sm"
        >
            <Modal.Section display="flex" justifyContent="flex-end">
                <Modal.CloseButton onClick={handleClose} disabled={loading} />
            </Modal.Section>
            <Modal.Content>
                <Modal.Section>
                    <Flex
                        textAlign="center"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        gap="md"
                    >
                        <ActivateTrialSubscriptionModalContent
                            data={data}
                            loading={loading}
                            error={error}
                            onActivateNow={handleSubmitToBff}
                            onClose={handleClose}
                        />
                    </Flex>
                </Modal.Section>
            </Modal.Content>
            <Modal.Footer />
        </Modal>
    );
};
