import { APP_VERSION } from 'constants/app';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { setupLandrDevTools } from '@landr/core';
import { ApplicationEnum } from '@landr/core.models';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'error/components/ErrorBoundary';
import setupPolyfills from 'utils/polyfills';
import { CONFIG } from 'utils/config';
import { AuthStateProvider } from 'contexts/AuthenticationContext/AuthStateProvider';
import ClientUpdateProvider from 'contexts/ClientUpdateContext/ClientUpdateContext';
import ModalProvider from 'contexts/ModalContext/ModalContext';
import ErrorBoundaryProvider from 'contexts/ErrorBoundaryContext/ErrorBoundaryContext';
import { lazyWithRetries } from 'helpers/lazyWithRetries';
import { apolloClient } from 'apollo/client/apolloClient';
import AppStyleProvider from 'components/AppStyleProvider';
import { ToastProvider } from '@landr/maestro';
import { Metadata } from 'components/Metadata';
import { LinkILokModalProvider } from 'modals/LinkILokModal';
import { AuthStepUpModalProvider } from 'modals/AuthStepUpModal';
import { ActivateTrialSubscriptionModalProvider } from 'modals/ActivateTrialSubscriptionModal/useActivateTrialSubscriptionModal';
import 'index.css';
import CatalogSaleProvider from 'contexts/CatalogSalesContext/CatalogSalesContext';
import PricingProviderWithToken from 'contexts/PricingProviderWithToken/PricingProviderWithToken';
import { Bootstrap } from './Bootstrap';

const App = lazyWithRetries(() => import('./App'));

const providers = [
    {
        component: ErrorBoundaryProvider,
        props: { key: 'ErrorBoundaryProvider' },
    },
    {
        component: ErrorBoundary,
        props: {
            variant: 'global',
            displayName: 'Root',
            key: 'ErrorBoundary',
        },
    },
    { component: Suspense, props: { fallback: null, key: 'Suspense' } },
    { component: BrowserRouter, props: { key: 'BrowserRouter' } },
    {
        component: Bootstrap,
        props: { key: 'Bootstrap' },
    },
    {
        component: AuthStateProvider,
        props: { key: 'AuthStateProvider' },
    },
    {
        component: PricingProviderWithToken,
        props: { key: 'PricingProviderWithToken' },
    },
    {
        component: ApolloProvider,
        props: { key: 'ApolloProvider', client: apolloClient },
    },
    { component: AppStyleProvider, props: { key: 'AppStyleProvider' } },
    {
        component: ToastProvider,
        props: { key: 'ToastProvider' },
    },
    { component: ModalProvider, props: { key: 'ModalProvider' } },
    { component: ClientUpdateProvider, props: { key: 'ClientUpdateProvider' } },
    { component: CatalogSaleProvider, props: { key: 'CatalogSaleProvider' } },
    {
        component: Metadata,
        props: { key: 'Metadata' },
    },
    {
        component: LinkILokModalProvider,
        props: { key: 'LinkILokModalProvider' },
    },
    {
        component: AuthStepUpModalProvider,
        props: { key: 'AuthStepUpModalProvider' },
    },
    {
        component: ActivateTrialSubscriptionModalProvider,
        props: { key: 'ActivateTrialSubscriptionModalProvider' },
    },
];

const renderRoot = () =>
    providers.reduceRight(
        (acc, next) =>
            React.createElement(
                next.component as React.FC<{ key: string }>,
                {
                    ...next.props,
                },
                [acc],
            ),
        React.createElement(App, { key: 'App' }, null),
    );

const container = document.getElementById('root');
const root = createRoot(container!);

setupPolyfills();
setupLandrDevTools(ApplicationEnum.SynchroArtsApp, APP_VERSION, CONFIG);
root.render(renderRoot());
