import React, { PropsWithChildren } from 'react';
import { createModalContext } from 'modals/createModalContext';
import { ActivateTrialSubscriptionModal } from './ActivateTrialSubscriptionModal';

const {
    modalProvider: ActivateTrialSubscriptionModalContextProvider,
    modalContext: ActivateTrialSubscriptionContext,
    useModal: useActivateTrialSubscriptionModal,
} = createModalContext<{}>();

const ActivateTrialSubscriptionModalProvider: React.FC<
    PropsWithChildren<void>
> = ({ children }) => {
    return (
        <ActivateTrialSubscriptionModalContextProvider>
            {children}
            <ActivateTrialSubscriptionModal />
        </ActivateTrialSubscriptionModalContextProvider>
    );
};

export {
    ActivateTrialSubscriptionModalProvider,
    ActivateTrialSubscriptionContext,
    useActivateTrialSubscriptionModal,
};
