import { Flex } from '@landr/maestro';
import React, { PropsWithChildren } from 'react';

export const IconBackground: React.FC<PropsWithChildren<unknown>> = ({
    children,
}) => {
    return (
        <Flex
            position="relative"
            justifyContent="center"
            alignItems="center"
            width="80px"
            height="80px"
            backgroundColor="primary.100"
            borderRadius="20px"
        >
            {children}
        </Flex>
    );
};
