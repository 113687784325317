import React from 'react';
import { MutationResult } from '@apollo/client';
import { ActivateTrialSubscriptionMutation } from 'types/generated/graphql';
import {
    Box,
    Button,
    CheckOutlined,
    CrownOutlined,
    Heading,
    Text,
} from '@landr/maestro';
import { IconBackground } from 'components/IconBackground';

type ActivateTrialSubscriptionModalContentProps = {
    onClose(): void;
    onActivateNow(): void;
    loading: MutationResult<ActivateTrialSubscriptionMutation>['loading'];
    error: MutationResult<ActivateTrialSubscriptionMutation>['error'];
    data: MutationResult<ActivateTrialSubscriptionMutation>['data'];
};

export const ActivateTrialSubscriptionModalContent: React.FC<
    ActivateTrialSubscriptionModalContentProps
> = ({ loading, error, data, onClose, onActivateNow }) => {
    if (error) {
        return (
            <>
                <Heading as="h5" size="xl" mb={0}>
                    Try again
                </Heading>
                <Box>
                    <Text color="neutral.400">
                        An error has occurred. Please try again.
                    </Text>
                </Box>
                <Box>
                    <Button onClick={onClose} isRounded>
                        Got it
                    </Button>
                </Box>
            </>
        );
    }

    if (data) {
        return (
            <>
                <IconBackground>
                    <CheckOutlined color="primary.900" size="xl" />
                </IconBackground>
                <Heading as="h5" fontSize="xl" mb={0}>
                    You're all set!
                </Heading>
                <Box>
                    <Text color="neutral.400">
                        You will soon have full access to your Synchro Arts
                        Artist.
                    </Text>
                </Box>
                <Box>
                    <Button onClick={onClose} isRounded>
                        Got it
                    </Button>
                </Box>
            </>
        );
    }

    return (
        <>
            <IconBackground>
                <CrownOutlined color="primary.900" size="xl" />
            </IconBackground>
            <Heading as="h5" fontSize="xl" mb={0}>
                Activate your subscription
            </Heading>
            <Box>
                <Text color="neutral.400">
                    You're still on your free trial. Want to get full Synchro
                    Arts Artist features?
                </Text>
            </Box>
            <Box>
                <Button onClick={onActivateNow} isLoading={loading} isRounded>
                    Start plan
                </Button>
            </Box>
        </>
    );
};
