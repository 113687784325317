import { gql } from '@apollo/client';

export const ItemDetailsFragment = gql`
    fragment ItemDetailsFragment on CatalogItem {
        id
        slug
        blurb
        imageUrl
        videoUrl
        name
        status
        upsellInformations {
            alcCode
            planCode
        }
        isLocked
        canStartTrial
        trialDurationDays
        unavailabilityReason
        installer {
            id
            latestInstallerVersion
        }
        codes {
            id
            type
            labelCode
            codes {
                id
                code
                labelOverride
                bundleName
                planCode
                isTrialing
            }
        }
        userManualUrl
        applicableDiscount {
            type
            percent
        }
    }
`;

export const PluginCatalogQuery = gql`
    query PluginCatalog {
        iLokAccount {
            id
            iLokId
        }
        pluginCatalog {
            ...ItemDetailsFragment
        }
        subscriptionInclusions {
            id
            externalIncludedPlugins {
                id
                slug
                imageUrl
                name
            }
            extraInclusions {
                id
                numberOfExtraPlugins
            }
            numberOfAvailablePluginsWithYearlySub
        }
    }
    ${ItemDetailsFragment}
`;

export const PluginItemQuery = gql`
    query PluginItem($slug: String!) {
        pluginItem(slug: $slug) {
            ...ItemDetailsFragment
        }
    }
    ${ItemDetailsFragment}
`;

export const StartTrialMutation = gql`
    mutation startTrial($productId: ID!) {
        pluginStartTrial(productId: $productId) {
            catalogItem {
                ...ItemDetailsFragment
                applicableDiscount {
                    type
                    percent
                }
            }
        }
    }
    ${ItemDetailsFragment}
`;

export const LinkILokAccountMutation = gql`
    mutation linkILokAccount($iLokId: String!) {
        linkILokAccount(iLokId: $iLokId) {
            iLokAccount {
                id
                iLokId
            }
            iLokLicenses {
                ...ItemDetailsFragment
                applicableDiscount {
                    percent
                }
            }
        }
    }
    ${ItemDetailsFragment}
`;

export const ILokAccountQuery = gql`
    query ILokAccount {
        iLokAccount {
            id
            iLokId
        }
    }
`;

export const ValidateILokAccountQuery = gql`
    query ValidateILokAccount($iLokId: String!) {
        validateILokAccount(iLokId: $iLokId) {
            isValid
        }
    }
`;

export const SyncILokLicensesMutation = gql`
    mutation syncILokLicenses {
        syncILokLicenses {
            iLokLicenses {
                ...ItemDetailsFragment
                applicableDiscount {
                    percent
                }
            }
        }
    }
    ${ItemDetailsFragment}
`;

export const GetLicencesActivationsQuery = gql`
    query getLicencesActivations($licenceId: String!) {
        getLicencesActivations(licenceId: $licenceId) {
            id
            licenceActivations {
                activationId
                machineName
                os
                activatedAt
            }
        }
    }
`;

export const CancelLicenceActivationMutationDefinition = gql`
    mutation cancelLicenceActivation(
        $licenceId: String!
        $activationId: String!
    ) {
        cancelLicenceActivation(
            licenceId: $licenceId
            activationId: $activationId
        ) {
            id
            licenceActivations {
                activationId
                machineName
                os
                activatedAt
            }
        }
    }
`;
